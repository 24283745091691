

const MINI_PROJECT_DATA = [
    {
        id: 1,
        title: 'Minimalist Paint',
        imageUrl: './canvas_4.png',
        desc: 'Paint-inspired web app with a minimalist layout.',
        techUsed: 'HTML5 Canvas, CSS, JavaScript, jQuery',
        github: 'https://github.com/bleunggithub/3canvas',
        url: 'http://canvasproject20200918.surge.sh/',
    },
    {
        id: 2,
        title: 'Simple CRUD GraphQL API',
        imageUrl: 'https://github.com/bleunggithub/simple-graphql-api/blob/main/screenshot.png?raw=true',
        desc: 'Practice implementing a CRUD GraphQL API Server',
        techUsed: 'GraphQL, Apollo Client, Node.js, Express.js, React',
        github: 'https://github.com/bleunggithub/simple-graphql-api',
        url: 'https://guarded-oasis-33299.herokuapp.com/',
    },
]

export default MINI_PROJECT_DATA.reverse();