const PROJECT_DATA = [
    {
        id: 1,
        title: 'JengSpot',
        imageUrl: './jengspot_4.png',
        desc: '(Full stack) Instagram / Twitter-inspired web app promoting Hong Kong hidden gems.',
        techUsed: 'Node.js, Express.js, PostgresQL, Knex.js, Passport.js, EJS, REST API (Imgur, Google Maps, etc.)',
        github: 'https://github.com/bleunggithub/jengSpot',
        url: 'https://polar-coast-70441.herokuapp.com/',
    },
    {
        id: 2,
        title: 'Portfol.io',
        imageUrl: './portfolio_9.png',
        desc: '(Full Stack) A show-and-tell platform for developers to showcase their portfolio visually and also to get inspired. (Progressive Web App - PWA)',
        techUsed: 'React, Redux, Node.js, Express.js, Facebook OAuth, Google OAuth, JWT, PostgresQL, AWS, CircleCI, Cloudinary API, RESTCountries API',
        github: 'https://github.com/bleunggithub/Portfol.io_frontend',
        githubBackend: 'https://github.com/bleunggithub/Portfol.io_backend',
        url: 'https://portfol-io.netlify.app/',
    },
    {
        id: 3,
        title: 'Recruitment Agency Website',
        imageUrl: './knight_recruit_site.png',
        desc: '(JAMstack) A commercial website with search function for a recruitment agency. (Commercial project, code available upon request only.)',
        techUsed: 'Gatsby.js, Prismic.io, GraphQL, TailwindCss',
        url: 'https://knight-recruits-test-site.netlify.app/',
    },
]

export default PROJECT_DATA.reverse();