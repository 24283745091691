import React, { Component } from 'react'
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import ProjectCards from '../components/ProjectCards'
import MiniProjectCards from '../components/MiniProjectCards'
import PROJECT_DATA from '../components/projectData'
import MINI_PROJECT_DATA from '../components/miniProjectData'

export default class Work extends Component {
    constructor() {
        super()
        this.state = {
            projects: PROJECT_DATA,
            miniProjects: MINI_PROJECT_DATA,
        }
    }
    render() {
        return (
        <Layout>
            <SEO title="Portfolio" />
            <div className="flex flex-wrap justify-around">
                {this.state.projects.map(({ id, ...otherProjectProps }) => (
                    <ProjectCards key={id} {...otherProjectProps} />
                ))}
            </div>
            <div className="m-2"><h1>Mini Projects</h1></div>
            <div className="flex flex-wrap justify-start">
                {this.state.miniProjects.map(({ id, ...otherProjectProps }) => (
                    <MiniProjectCards key={id} {...otherProjectProps} />
                ))}
            </div>
        </Layout>
        )
    }
}

