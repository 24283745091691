import React from 'react'

import GithubLogoSVG from './images/GithubLogoSVG'
import ServerSVG from './images/ServerSVG'
import LinkSVG from './images/LinkSVG'

export default function ProjectCards({imageUrl, title, desc, techUsed, github, githubBackend, url}) {
    return (
        <div className="flex flex-col justify-start w-full mx-4 my-6 md:w-1/4 md:mx-5 rounded-sm">
            <div className="bg-contain bg-center bg-no-repeat mb-6" style={{backgroundImage:`url(${imageUrl})`, height: '200px'}} />
            <div className="px-4">
                <h1 className="font-bold"><span style={highlightText}>{title}</span></h1>
                <p className="mb-4">{desc}</p>
                <h2 className="font-bold"><span style={highlightText}>Tech Used</span></h2>
                <p className="mb-4">{techUsed}</p>
                <div className="flex justify-evenly my-6">
                    <a href={github} target="_blank" rel="noopener noreferrer">
                        <GithubLogoSVG style={inline} />
                    </a>
                    {githubBackend ?
                        <a href={githubBackend} target="_blank" rel="noopener noreferrer">
                            <ServerSVG style={inline} />
                        </a> : ""}
                    {url ?
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <LinkSVG style={inline} />
                    </a>:""
                    }
                </div>
            </div>
        </div>
    )
}

const highlightText = {
    background: "linear-gradient(to top, #ffff00cb 50%, transparent 50%)"
}

const inline = {
    display: "inline"
}